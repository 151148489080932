<template>
<div class="mx-auto">
        <div class="">
            <Loading :isLoading= "this.isLoading" />            
            <div>
                <form @submit="Next" @submit.prevent="validateRfc()"  id="form" class="px-8 pb-8 mb-4" style="text-align: left;">
                    <br>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">                            
                            <center>
                                <p class="title">
                                Registro de Datos Personales</p>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">                            
                            <center>
                                <h4 class="steps text-base lg:text-base xl:text-base mt-5 text-center">Paso 2 de 10</h4>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <center>
                                <p class="subTitle">
                                    Datos de vivienda</p>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                        <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                            <div class="border">
                                <div class="form-group my-3 mr-5 text-left">
                                    <label style="text-align: left;" class="label-input">Tipo de vivienda:</label>
                                    <div class="input-group" style="">
                                        <input type="radio" name="rdbTipoVivienda" value="PROPIA" v-model="typeHousing" required style="margin-left:10px"> 
                                        <label class="label-input-radio" style="margin-left:5px;">Propia</label>
                                        <input type="radio" name="rdbTipoVivienda" value="RENTADA" v-model="typeHousing" style="margin-left:38px"> 
                                        <label class="label-input-radio" style="margin-left:5px;">Rentada</label> <br>
                                        <input type="radio" name="rdbTipoVivienda" value="PAGANDOLA" v-model="typeHousing" style="margin-left:10px"> 
                                        <label class="label-input-radio" style="margin-left:5px;">Pagandola</label> 
                                        <input type="radio" name="rdbTipoVivienda" value="CON FAMILIARES" v-model="typeHousing" style="margin-left:10px"> 
                                        <label class="label-input-radio" style="margin-left:5px;">Con Familiares</label> <br>
                                         <input type="radio" name="rdbTipoVivienda" value="PRESTADA" v-model="typeHousing" style="margin-left:10px"> 
                                         <label class="label-input-radio" style="margin-left:5px;">Prestada</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                                    <div class="ml-5 mt-5 text-left" >
                                        <div class="center" style="width:200px">
                                            <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px">
                                                <label class="label-input">Inicio de residencia:</label>
                                                <!-- <input type="text" id="datepickerDateHomeResidence" class=" outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                                readonly="readonly" minlength="10" maxlength="10" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="dateHomeResidence"
                                                       v-on:change="validateDateHomeResidence()" required
                                                placeholder="Seleccionar fecha"/> -->
                                                  <date-picker
                                                          simple
                                                          color="#810042"
                                                          placeholder="Fecha inicio"
                                                          locale="es"
                                                          input-format="DD/MM/YYYY"
                                                          @change="selectDate"
                                                          v-model="dateHomeResidence"
                                                          id="dateHomeResidence"
                                                          :localeConfig="{
                                                            es: {
                                                              displayFormat: 'DD/MM/YYYY',
                                                                lang: {
                                                                  year: 'Año',
                                                                  month: 'Mes',
                                                                  day: 'Día',
                                                                  label: '',
                                                                  submit: 'Seleccionar',
                                                                  cancel: 'Cancelar',
                                                                  now: 'Hoy',
                                                                  nextMonth: 'Siguiente',
                                                                  prevMonth: 'Anterior',
                                                                },
                                                              }
                                                          }" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <center>
                                <p class="subTitle" style="margin-top:-20px;">
                                    Datos de Escolaridad</p>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                        <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                            <div>
                                <div class="center">
                                    <div class="form-group my-3 mr-5 text-left">
                                        <label class="label-input">Escolaridad:</label>
                                        <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " ref="stateSelected" required v-model="scholarship" style="text-transform: uppercase;">
                                            <option value="PRIMARIA">Primaria</option>
                                            <option value="SECUNDARIA">Secundaria</option>
                                            <option value="PREPARATORIA">Preparatoria</option>
                                            <option value="TÈCNICA COMERCIAL">Técnica comercial</option>
                                            <option value="LICENCIATURA">Licenciatura</option>
                                            <option value="POSGRADO">Posgrado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <center>
                                <p class="subTitle" style="margin-top:-20px;">
                                    Estado Civil</p>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                        <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                            <div class="border">
                                <div class="form-group my-3 mr-5 text-left">
                                    <label style="text-align:left;" class="label-input">Estado Civil:</label>
                                    <div class="input-group" style="">
                                        <input type="radio" name="rdbTipoEstadoCivil" value="SOLTERO(A)" v-model="typeMaritalStatus" v-on:change="maritalStatusCollapseInfoWife()" required style="margin-left:10px"> 
                                        <label for="" class="label-input-radio" style="margin-left:5px;">Soltero(a)</label>
                                        <input type="radio" name="rdbTipoEstadoCivil" value="CASADO(A)" v-model="typeMaritalStatus" v-on:change="maritalStatusCollapseInfoWife()" style="margin-left:34px"> 
                                        <label for="" class="label-input-radio" style="margin-left:5px;">Casado(a)</label> <br>
                                        <input type="radio" name="rdbTipoEstadoCivil" value="DIVORCIADO(A)" v-model="typeMaritalStatus" v-on:change="maritalStatusCollapseInfoWife()" style="margin-left:10px"> 
                                        <label for="" class="label-input-radio" style="margin-left:5px;">Divorciado(a)</label>
                                        <input type="radio" name="rdbTipoEstadoCivil" value="VIUDO(A)" v-model="typeMaritalStatus" v-on:change="maritalStatusCollapseInfoWife()" style="margin-left:10px"> 
                                        <label for="" class="label-input-radio" style="margin-left:5px;">Viudo(a)</label> <br>
                                        <input type="radio" name="rdbTipoEstadoCivil" value="CONCUBINATO" v-model="typeMaritalStatus" v-on:change="maritalStatusCollapseInfoWife()" style="margin-left:10px"> 
                                        <label for="" class="label-input-radio" style="margin-left:5px;">Concubinato</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" id="divRegimenBienes">
                                <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                                    <div class="ml-5 mt-5 text-left" >
                                        <div class="form-group my-3 mr-5 text-left">
                                            <label class="label-input">Régimen de Bienes:</label>
                                            <div class="input-group" style="">
                                                <input type="radio" name="rdbTipoRegimenBienes" id="typeRegime" value="SEPARADOS" v-model="typeRegime" style="margin-left:10px"> 
                                                <label for="" class="label-input-radio">Separados</label>
                                                <input type="radio" name="rdbTipoRegimenBienes" value="MANCUMUNADOS" v-model="typeRegime" style="margin-left:36px"> 
                                                <label for="" class="label-input-radio">Mancumunados</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1" id="divConyuge">
                        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                            <div class="row">
                                <center>
                                    <p class="subTitle">
                                        Datos del Concubino</p>
                                </center>
                            </div>
                        </div>
                        <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                            <div class="border">
                                <div class="center">
                                    <div class="form-group my-3 mr-5 text-left">
                                        <label class="label-input">R.F.C:</label>
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " id="rfc"
                                        minlength="13" maxlength="13" v-model="rfcConyuge" >
                                    </div>
                                    <div class="form-group my-3 mr-5 text-left">
                                        <label class="label-input">Apellido Paterno:</label>
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " id="paternal"
                                        minlength="3" maxlength="30" pattern="[a-zA-ZéñúóáíáÍÑÁÉÓÚ ]{2,30}" v-model="paternalConyuge">
                                    </div>
                                    <div class="form-group my-3 mr-5 text-left">
                                        <label class="label-input">Apellido Materno:</label>
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " id="maternal"
                                        minlength="3" maxlength="30" pattern="[a-zA-ZéñúóáíáÍÑÁÉÓÚ ]{2,30}" v-model="maternalConyuge">
                                    </div>
                                    <div class="form-group my-3 mr-5 text-left">
                                        <label class="label-input">Primer Nombre:</label>
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " id="firstName"
                                        minlength="3" maxlength="30" pattern="[a-zA-ZéñúóáíáÍÑÁÉÓÚ ]{2,30}" v-model="firstNameConyuge">
                                    </div>
                                    <div class="form-group my-3 mr-5 text-left">
                                        <label class="label-input">Segundo Nombre:</label>
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " id="lastName"
                                        minlength="3" maxlength="30" pattern="[a-zA-ZéñúóáíáÍÑÁÉÓÚ ]{2,30}" v-model="lastNameConyuge">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                                    <div class="ml-5 mt-5 text-left" >
                                        <div class="form-group my-3 mr-5 text-left">
                                            <label class="label-input">Género:</label>
                                            <div class="input-group" style="">
                                                <input type="radio" name="rdbSexo" id="gender" value="FEMENINO" v-model="genderConyuge" style="margin-left:10px"> 
                                                <label for="" class="label-input-radio">Femenino</label>
                                                <input type="radio" name="rdbSexo" value="MASCULINO" v-model="genderConyuge" style="margin-left:36px"> 
                                                <label for="" class="label-input-radio">Masculino</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="bg-gray-0 px-0 py-0 m-0" style="display:flex;flex-wrap: wrap;justify-content:center;">
                      <div>
                        <button class="next_button" type="submit" >Siguiente</button>
                      </div>
                    </div>
                    <div class="bg-gray-0 px-0 py-0 m-0" style="display:flex;flex-wrap: wrap;justify-content:center;margin-top:10px;">
                      <div>
                        <button class="back_button" v-on:click="goToBack()" type="button" >Atrás</button>
                      </div>
                    </div>
                    <br>
                </form>
            </div>
        </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>

<script>
import('@/assets/css/stylesheet.css')
import { saveRequest,validityRFC, validateMatchRfcWithNameWithoutDate, getAdmissionRequest,updateOnlyStatus } from "@/api/user";
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import moment from "moment";
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      personId: null,
      rfc: "",
      genderConyuge: "",
      firstNameConyuge: "",
      lastNameConyuge: "",
      maternalConyuge: "",
      paternalConyuge: "",
      rfcConyuge: "",
      typeRegime: "",
      typeMaritalStatus: "",
      scholarship: "",
      dateHomeResidence: "",
      maxDate: "",
      minYearDatePicker: "",
      typeHousing: "",
      person_id: "",
      member_id: "",
      registerExist: false,
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi:process.env.VUE_APP_CCAPI,
      user_id:null,
      admission_request_id:null,
      admission_status:null,
      isErrorRFC: null,
    };
  },
  name: "Registrar_datos_personales_dos",
  components: {
    MenuLateral,
    Loading,
  },
  props: {
    msg: String,
  },
  watch: {
    dateHomeResidence(value) {
      console.log("watch", value);
    },
  },
  async mounted() {
    document
      .getElementById("paternal")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;
        if (regex.test(this.paternalConyuge) != true) {
          this.paternalConyuge = this.paternalConyuge.replace(
            /[^a-zA-ZÀ-ÿ@\s]+/,
            ""
          );
        }
      });

    document
      .getElementById("maternal")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;
        if (regex.test(this.maternalConyuge) != true) {
          this.maternalConyuge = this.maternalConyuge.replace(
            /[^a-zA-ZÀ-ÿ@\s]+/,
            ""
          );
        }
      });

    document
      .getElementById("firstName")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;
        if (regex.test(this.firstNameConyuge) != true) {
          this.firstNameConyuge = this.firstNameConyuge.replace(
            /[^a-zA-ZÀ-ÿ@\s]+/,
            ""
          );
        }
      });

    document
      .getElementById("lastName")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;
        if (regex.test(this.lastNameConyuge) != true) {
          this.lastNameConyuge = this.lastNameConyuge.replace(
            /[^a-zA-ZÀ-ÿ@\s]+/,
            ""
          );
        }
      });
  },
  async mounted() {
    await this.loadInfo();
    //await this.validateRFC();
    this.establisDateMaxHomeResidence();
    this.establishYearMinDatePicker();
    var self = this;
    // $(function () {
    //   $.datepicker.setDefaults($.datepicker.regional["es"]);
    //   $("#datepickerDateHomeResidence").datepicker({
    //     changeMonth: true,
    //     changeYear: true,
    //     yearRange: "1970:+nn",
    //     dateFormat: "dd-mm-yy",
    //     maxDate: new Date(self.maxDate),
    //     monthNames: [
    //       "Enero",
    //       "Febrero",
    //       "Marzo",
    //       "Abril",
    //       "Mayo",
    //       "Junio",
    //       "Julio",
    //       "Agosto",
    //       "Septiembre",
    //       "Octubre",
    //       "Noviembre",
    //       "Diciembre",
    //     ],
    //     monthNamesShort: [
    //       "Ene",
    //       "Feb",
    //       "Mar",
    //       "Abr",
    //       "May",
    //       "Jun",
    //       "Jul",
    //       "Ago",
    //       "Sep",
    //       "Oct",
    //       "Nov",
    //       "Dic",
    //     ],
    //     dayNames: [
    //       "Domingo",
    //       "Lunes",
    //       "Martes",
    //       "Miércoles",
    //       "Jueves",
    //       "Viernes",
    //       "Sábado",
    //     ],
    //     dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Juv", "Vie", "Sáb"],
    //     dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
    //     onSelect: function (selectedDate, datePicker) {
    //       self.dateHomeResidence = selectedDate;
    //     },
    //   });
    // });
  },
  methods: {
    async validateRfc(){
      var resultRfc = false
      if(this.rfcConyuge.length == 13){
        await validityRFC(
          this.rfcConyuge
        )
        .then((response) => {
          resultRfc = !!response.data.response.rfc_is_valid
        })
        .catch((error) => {
          console.log("Error al validar el RFC: ", error);
          Swal.fire({
            title: "Aviso",
            text: "RFC no valido.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
        });
        
        if(!resultRfc){
          Swal.fire({
              title: "Aviso",
              text:
                  "El RFC ingresado no es correcto.",
              icon: "error",
              confirmButtonColor: '#FEB72B',
          });
        }
        return resultRfc
      }
    },
    async validateMatchRfc(){
      var responsee = false;
      await validateMatchRfcWithNameWithoutDate(
          this.rfcConyuge.toUpperCase(),
          this.firstNameConyuge.trim(),
          this.lastNameConyuge.trim(),
          this.paternalConyuge.trim(),
          this.maternalConyuge.trim()
      ).then((response) => {
        responsee = true;
      }).catch((error) => {
      }).finally(() => {
      })
      if(responsee == false){
        Swal.fire({
          title: "Aviso",
          text:
              "La información del cónyuge no coincide con el rfc",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
      }
      return responsee;
    },
    async SpouseInformationIqualOwnerInformation(){
      var auth = getTokenDecoden();
      
      var list_info_validations = []
      if(this.rfcConyuge != "" && auth.obj.person["rfc"].toLowerCase() == this.rfcConyuge.toLowerCase()){list_info_validations.push("Campo RFC del cónyuge deberá ser diferente al del titular")}
      // if(this.maternalConyuge != "" && auth.maternal_name.toLowerCase() == this.maternalConyuge.toLowerCase()){list_info_validations.push("Apellido materno")}
      // if(this.paternalConyuge != "" && auth.paternal_name.toLowerCase() == this.paternalConyuge.toLowerCase()){list_info_validations.push("Apellido paterno")}
      // if(this.firstNameConyuge != "" && auth.name.toLowerCase() == this.firstNameConyuge.toLowerCase()){list_info_validations.push("Primer nombre")}
      // if(this.lastNameConyuge != "" && auth.middle_name.toLowerCase() == this.lastNameConyuge.toLowerCase()){list_info_validations.push("Segundo nombre")}
      
      var info_validations = ""
      if(list_info_validations.length > 0){
        info_validations = list_info_validations.join(',')
        Swal.fire({
          title: "La información del cónyuge no puede ser igual a la del titular",
          text:
              info_validations,
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return true;
      }
      return false;
    },
    Logout() {
      sessionStorage.clear();
      //localStorage.clear();
      // window.location.href = "/";
      this.$router.push({path:"/"});
    },
    validateEmptyDate(date) {
      if (date == "" || date == null) {
        return true;
      }

      return false;
    },
    establisDateMaxHomeResidence() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd + " 00:00:00";
      this.maxDate = today;
    },
    establishYearMinDatePicker() {
      var today = new Date();
      var yyyy = today.getFullYear() - 100;

      this.minYearDatePicker = yyyy;
    },
    async maritalStatusCollapseInfoWife() {
      document.getElementById("gender").required = false;
      document.getElementById("typeRegime").required = false;
      document.getElementById("rfc").required = false;
      document.getElementById("firstName").required = false;
      document.getElementById("paternal").required = false;

      document.getElementById("divConyuge").style.visibility = "collapse";
      document.getElementById("divRegimenBienes").style.visibility = "collapse";

      if (
        this.typeMaritalStatus == "CASADO(A)" ||
        this.typeMaritalStatus == "CONCUBINATO"
      ) {
        document.getElementById("gender").required = true;
        document.getElementById("rfc").required = true;
        document.getElementById("firstName").required = true;

        document.getElementById("divConyuge").style.visibility = "visible";

        if (this.typeMaritalStatus == "CASADO(A)") {
          document.getElementById("typeRegime").required = true;
          document.getElementById("divRegimenBienes").style.visibility =
            "visible";
        }
      } else {
        this.gender = "";
        this.typeRegime = "";
        document.getElementById("rfc").value = "";
        document.getElementById("firstName").value = "";
        document.getElementById("lastName").value = "";
        document.getElementById("paternal").value = "";
        document.getElementById("maternal").value = "";

        this.rfcConyuge = "";
        this.paternalConyuge = "";
        this.maternalConyuge = "";
        this.firstNameConyuge = "";
        this.lastNameConyuge = "";
      }
      this.collapseSeccionConyuge();
    },
    addUpperCase(value) {
      if (value != "") {
        value = value.toUpperCase();
      }
      return value;
    },
    collapseSeccionConyuge() {
      if (
        this.typeMaritalStatus == "CASADO(A)" ||
        this.typeMaritalStatus == "CONCUBINATO"
      ) {
        document.getElementById("divConyuge").style.display = "block";
      } else {
        document.getElementById("divConyuge").style.display = "none";
      }
    },
    orderDateByDayMonthYear(date, typeSplit) {
      if(date==undefined)
        return;
      var elemtsOfDate = date.split(typeSplit);
      var day = elemtsOfDate[2];
      var month = elemtsOfDate[1];
      var year = elemtsOfDate[0];
      return day + "-" + month + "-" + year;
    },
    orderDateByYearMonthDay(date, typeSplit) {
      if(date==undefined)
        return;
      return moment(date.split(' ')[0].split(typeSplit).reverse().join("-")).format("YYYY-MM-DD");
    },
    formatDate(date) {
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    transformMessages() {
      var lista_errores = [];
      this.errors.forEach(function (element) {
        if (element == "El campo paternal_name no puede estar vacio") {
          lista_errores.push("El campo nombre paterno no puede estar vacio");
        } else if (element == "El campo name no puede estar vacio") {
          lista_errores.push("El campo nombre no puede estar vacio");
        } else {
          lista_errores.push(this.capitalizeTxt(element));
        }
      });
      this.errors = lista_errores;
    },
    capitalizeTxt(txt) {
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    },
    /*async validityRFC() {
      this.isLoading = true;
      if (this.rfcConyuge != null && this.rfcConyuge.length == 13) {
        await validityRFC(
            this.rfcConyuge
        )
            .then((response) => {
              this.isErrorRFC = true;
            })
            .catch((error) => {
              console.log("Error al validar el RFC: ", error);
              Swal.fire({
                title: "Aviso",
                text:
                    "El RFC ingresado no es correcto.",
                icon: "error",
              });
              this.isErrorRFC = false;
            })
            .finally( () => {
              this.isLoading = false;
            })
      }
      else{
        this.isErrorRFC = false;
        this.isLoading = false;
        Swal.fire({
          title: "Aviso",
          text:
              "El RFC ingresado debe tener 13 caracteres.",
          icon: "error",
        });
      }
    },*/
    async loadInfo() {
      var auth = getTokenDecoden();
      this.user_id = auth["id"];
      this.personId = auth.obj.person["id"];

      await this.searchPersonalData();
      await this.maritalStatusCollapseInfoWife();
    },
    async searchPersonalData() {
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      if(Object.entries(response).length != 0)
      {
        await this.getDataAdmissionUser(response);
      }else {
        await this.getAdmissionRequest();
      }
    },
    async getDataAdmissionUser(response){

      if(Object.entries(response).length != 0)
      {
        this.admission_request_id = response.data.response.admission_request.id;
        this.admission_status = response.data.response.admission_request.status;
        
        if(response.data.response.admission_request.admission_information.residence_status != null && response.data.response.admission_request.admission_information.residence_status != "")
        {
          this.typeHousing = response.data.response.admission_request.admission_information.residence_status;
          
          if(response.data.response.admission_request.admission_information.residence_start != null && response.data.response.admission_request.admission_information.residence_start != ""){
            this.dateHomeResidence = moment(
              response.data.response.admission_request.admission_information.residence_start
            ).format("DD/MM/YYYY");
          }
          
          this.scholarship = response.data.response.admission_request.admission_information.education_level;
          this.typeMaritalStatus = response.data.response.admission_request.admission_information.marital_status;
        } 

        if (this.typeMaritalStatus == "CASADO(A)" || this.typeMaritalStatus == "CONCUBINATO")
        {
          this.rfcConyuge = response.data.response.admission_request.admission_information.spouse_attributes.rfc;
          this.paternalConyuge = response.data.response.admission_request.admission_information.spouse_attributes.paternal_name;
          this.maternalConyuge = response.data.response.admission_request.admission_information.spouse_attributes.maternal_name;
          this.firstNameConyuge = response.data.response.admission_request.admission_information.spouse_attributes.name;
          this.lastNameConyuge = response.data.response.admission_request.admission_information.spouse_attributes.middle_name;
          this.genderConyuge = response.data.response.admission_request.admission_information.spouse_attributes.gender.toUpperCase();
          this.typeRegime = response.data.response.admission_request.admission_information.regime;
        }
      }
    },
    async getAdmissionRequest() {
      await getAdmissionRequest(
        sessionStorage.getItem("login"),
        this.personId
      )
      .then((response) => {
        this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        this.getDataAdmissionUser(response);
      })
      .catch((error) => {
        Swal.fire({
          title: "Aviso",
          text:
            "Se presentó un error. No fue posible obtener los datos de la solicitud.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        this.$router.push({path:"/cuenta/registro-datos-personales"});
      });
      return response
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      console.log(jsonPersonalInfo)
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    getJsonFromLocalStorageBy(user_id) {
      let _json = JSON.parse(sessionStorage.getItem(user_id));
      return _json;
    },
    async Next(e) {
      e.preventDefault();

      await this.maritalStatusCollapseInfoWife();
      if(this.rfcConyuge != "" && !await this.validateRfc()){
        return;
      }
      if(this.rfcConyuge != "" && !await this.validateMatchRfc()){
        return;
      }
      if(this.rfcConyuge != "" && await this.SpouseInformationIqualOwnerInformation()){
        return;
      }

      if (this.dateHomeResidence == "") {
        Swal.fire({
          title: "Aviso",
          text:
            "Favor de capturar el inicio de residencia",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return false;
      }
      
      if (
        this.typeMaritalStatus == "CASADO(A)" ||
        this.typeMaritalStatus == "CONCUBINATO"
      ) {
        if(this.paternalConyuge=="" && this.maternalConyuge == ""){        
          Swal.fire({
            title: "Aviso",
            text:
              "Favor de capturar por lo menos un apellido en Datos del Cónyuge",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      this.isLoading = true
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      if ( response.data.response.admission_request.admission_information.rfc == "") {
        this.showMessage("Aviso", "Debe llenar la primera pantalla", "error");
        this.isLoading = false;
        this.$router.push({ path: '/cuenta/registro-datos-personales' });
      }
      
      var infoJson = this.buildJson();

      if(this.admissionStatusIsValidToSave(this.admission_status))
      {
        console.log("!Guardando¡");
        await this.saveRequest(infoJson);
      }

      this.$router.push({ path: '/cuenta/informacion-economica' });
    },
    buildJson(admission_status = "personal_info") {
      var json = this.getJsonFromLocalStorageBy('admissionUser'+this.user_id);
      let dateHomeResidence=this.orderDateByYearMonthDay(this.dateHomeResidence,"/");
      json.data.response.admission_request.admission_information.marital_status = this.addUpperCase(this.typeMaritalStatus);
      json.data.response.admission_request.admission_information.regime = this.addUpperCase(this.typeRegime);
      console.log(this.dateHomeResidence)
      json.data.response.admission_request.admission_information.residence_start = dateHomeResidence;//this.orderDateByYearMonthDay(this.dateHomeResidence,"-");
      json.data.response.admission_request.admission_information.residence_status = this.addUpperCase(this.typeHousing);
      json.data.response.admission_request.admission_information.education_level = this.addUpperCase(this.scholarship);
      json.data.response.admission_request.admission_information.status = "activo";

      json.data.response.admission_request.admission_information.adress_attributes.residence_start = dateHomeResidence;//this.orderDateByYearMonthDay(this.dateHomeResidence,"-") ;
      json.data.response.admission_request.admission_information.residence_status = this.addUpperCase(this.typeHousing);
      if (
        this.typeMaritalStatus == "CASADO(A)" ||
        this.typeMaritalStatus == "CONCUBINATO"
      ) {
        json.data.response.admission_request.admission_information.spouse_attributes.rfc = this.addUpperCase(this.rfcConyuge);
        json.data.response.admission_request.admission_information.spouse_attributes.paternal_name = this.addUpperCase(this.paternalConyuge).trim();
        json.data.response.admission_request.admission_information.spouse_attributes.maternal_name = this.addUpperCase(this.maternalConyuge).trim();
        json.data.response.admission_request.admission_information.spouse_attributes.name = this.addUpperCase(this.firstNameConyuge).trim();
        json.data.response.admission_request.admission_information.spouse_attributes.middle_name = this.addUpperCase(this.lastNameConyuge).trim();
        json.data.response.admission_request.admission_information.spouse_attributes.gender = this.genderConyuge.toLowerCase();
      }else{
        json.data.response.admission_request.admission_information.spouse_attributes.rfc = "";
        json.data.response.admission_request.admission_information.spouse_attributes.paternal_name = "";
        json.data.response.admission_request.admission_information.spouse_attributes.maternal_name = "";
        json.data.response.admission_request.admission_information.spouse_attributes.name = "";
        json.data.response.admission_request.admission_information.spouse_attributes.middle_name = "";
        json.data.response.admission_request.admission_information.spouse_attributes.gender = "";
      }
      
      this.setPersonalInfoToLocalStorage('admissionUser'+this.user_id, json)

      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_request_id,
        user_id: this.user_id,
        change_for: admission_status,
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    async saveRequest(jsonData){
      this.isLoading=true;
      jsonData.token_auth = sessionStorage.getItem("login");
      await saveRequest(
        jsonData
      )
        .then((response ) => {
          console.log(response)
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        })
        .catch((error) => {
          console.log(
            "Se presento un error al guardar la información personal: ",
            error
          );
          Swal.fire({
            title: "Aviso",
            text:
              "Se presentó un error. No fue posible registrar los datos de la solicitud.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
          // window.location.href = "/cuenta/registro-datos-personales";
          this.$router.push({path:"/cuenta/registro-datos-personales"});
        }).finally( () => {this.isLoading=false})
    },
    showMessage(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        confirmButtonColor: '#FEB72B',
      });
    },
    async goToBack() {
      await updateOnlyStatus(sessionStorage.getItem("login"), this.admission_request_id, "update_personal_info").then(() => {
        this.$router.push({path:"/cuenta/completar-registro"});
      });
    },
    admissionStatusIsValidToSave( status ) {
      const validStatuses = [
        "autorizo_descuento_aceptado",
        "video_completo",
        "asignada",
        "en_revision",
        "rechazada",
        "cancelada",
        "identidad_validada"
      ];

      return (validStatuses.find(value => value == status))? false: true;
    },
    selectDate(event){
      if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
        Swal.fire({
          title: "Aviso",
          text:
            "La fecha de inicio de residencia tiene que ser menor a la actual.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.dateHomeResidence = null
        return;
      }
      else{
        this.dateHomeResidence = moment(event._d).format("DD/MM/YYYY")
      }
    }
  },
};
</script>

<style>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
  .border {
    border-right: 1px dashed #333;
    border-top: 1px dashed #ffffff;
    border-left: 1px dashed #ffffff;
    border-bottom: 1px dashed #ffffff;
  }
}

@media (max-width: 766px) {
  .border {
    margin-left: 9px;
    margin-right: -15px;
    border-right: 1px dashed #ffffff;
    border-top: 1px dashed #ffffff;
    border-left: 1px dashed #ffffff;
    border-bottom: 1px dashed #ffffff;
  }
}

#button_popups {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #feb72b;
  color: #fff;
  font-weight:bold;
  width: 250px;
  height: 40px;
  padding-top: 0%;
  font-size:16px;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px;
  }
}
.vpd-input-group input{
  border: 2px solid rgba(205, 205, 205, 0.416) !important;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  padding: 5px !important;
  box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important;
}
</style>